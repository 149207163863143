import React from "react"

import Seo from "../components/seo"
import { motion } from "framer-motion"
import { motionItem } from "../utils/motion"
import styled, { css } from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import WorkflowLine from "../animation/workflowLine"
import uniqid from "uniqid"
import WorkflowInfo from "../components/workflowInfo"
import Breakpoints from "../utils/breakpoints"

import BriefingIcon from "../svg/workflow/briefing.svg"
import ResearchIcon from "../svg/workflow/research.svg"
import RoadmapIcon from "../svg/workflow/roadmap.svg"
import CreateIcon from "../svg/workflow/create.svg"
import RebriefingIcon from "../svg/workflow/rebriefing.svg"
import LaunchIcon from "../svg/workflow/launch.svg"
import EvaluationIcon from "../svg/workflow/evaluation.svg"
import DocsIcon from "../svg/workflow/documentation.svg"
import SmileIcon from "../svg/workflow/smile.svg"
import CheckIcon from "../svg/workflow/check.svg"
import BugIcon from "../svg/workflow/bug.svg"

const data = [
  {
    image: (
      <StaticImage
        src={
          "../images/workflow/executive-manager-using-online-teleconference-conversation-internet-talking-people-videoconference-call-remote-telecommunication-wireless-business-meeting-handheld-shot.jpg"
        }
        alt="executive manager using online teleconference conversation internet talking people videoconference call remote telecommunication"
      />
    ),
    icon: <BriefingIcon />,
    title: "Briefing",
    text: `Beim Briefing spreche ich als Webentwickler mit meinen Kundinnen und Kunden, 
    um ihre Bedürfnisse und Anforderungen an die Webseite oder Webanwendung zu verstehen. Ich kläre, 
    welche Ziele die Webseite erreichen soll, wer die Zielgruppe ist und welche Funktionalitäten benötigt werden. 
    Das Briefing ist die Grundlage für die Entwicklung eines erfolgreichen Webprojekts.`,
  },
  {
    image: (
      <StaticImage
        src={"../images/workflow/homepage-concept-with-search-bar.jpg"}
        alt="homepage concept with search bar"
      />
    ),
    icon: <ResearchIcon />,
    title: "Research and Data Preparation",
    text: `Bevor ich mit der Planung beginne, 
    sammle ich als Webentwickler umfassende Informationen zu Ihrem Unternehmen und Ihrer Zielgruppe. 
    Ich analysiere Ihre Wettbewerber und die aktuellen Markttrends, 
    um eine fundierte Entscheidungsgrundlage zu schaffen. Zudem bereite ich Ihre vorhandenen Daten und Inhalte auf, 
    um eine reibungslose Integration in das neue Webprojekt sicherzustellen.`,
  },
  {
    image: <StaticImage src={"../images/workflow/roadmap.jpg"} alt="Roadmap" />,
    icon: <RoadmapIcon />,
    title: "Planing and Strategy",
    text: `Nach dem Research und der Datenanalyse beginne ich als Webentwickler mit der Planung und Strategieentwicklung. 
    Ich erstelle ein Konzept und eine Roadmap, die auf Ihre Bedürfnisse und Ziele zugeschnitten sind. 
    Dabei lege ich die Struktur und Funktionalitäten der Webseite oder Webanwendung fest und 
    berücksichtige dabei auch Ihre Corporate Identity.
    `,
  },
  {
    image: (
      <StaticImage src={"../images/workflow/coding-man.jpg"} alt="Coding Man" />
    ),
    icon: <CreateIcon />,
    title: "Create",
    text: `Nach der Planung setze ich als Webentwickler Ihre Webseite oder Webanwendung um. 
    Ich erstelle das Design, entwickle die Backend- und Frontend-Funktionalitäten und sorge 
    für eine optimale Nutzerfreundlichkeit und Benutzererfahrung. Dabei achte ich darauf, 
    dass Ihre Webseite oder Webanwendung schnell, sicher und zuverlässig ist.`,
  },
  {
    image: (
      <StaticImage
        src={
          "../images/workflow/two-businessmen-pointing-laptop-screen-while-discussing.jpg"
        }
        alt="Two businessmen pointing laptop screen while discussing"
      />
    ),
    icon: <RebriefingIcon />,
    title: "Rebriefing",
    text: `Nach der Erstellung des ersten Entwurfs bespreche ich als Webentwickler gemeinsam mit Ihnen nochmals die Ergebnisse. 
    Dabei berücksichtige ich Ihre Wünsche und Anregungen und passe das Konzept entsprechend an und implementiere etwaige Änderungswünsche.`,
  },
  {
    image: <StaticImage src={"../images/workflow/code.jpg"} alt="Code" />,
    icon: <BugIcon />,
    title: "Polishing and Refactoring",
    text: `Nach dem Rebriefing verbessere ich als Webentwickler den Code und die Funktionalitäten der Webseite oder Webanwendung. 
    Ich sorge für eine hohe Qualität und Sicherheit und beseitige Fehler.

    `,
  },
  {
    image: (
      <StaticImage
        src={
          "../images/workflow/diverse-team-coworkers-looking-business-charts-documents-analyzing-research-information-statistics-diagrams-review-people-using-papers-with-infographics-data-report-close-up.jpg"
        }
        alt="Diverse team coworker looking business charts"
      />
    ),
    icon: <CheckIcon />,
    title: "Approval",
    text: `Bevor die Webseite oder Webanwendung online geht, prüfe ich als Webentwickler alle Funktionalitäten und 
    Inhalte auf Herz und Nieren. Ich stelle sicher, dass alles einwandfrei funktioniert und alle Informationen korrekt sind.`,
  },
  {
    image: (
      <StaticImage
        src={
          "../images/workflow/digital-world-banner-background-remixed-from-public-domain-by-nasa.jpg"
        }
        alt="Digital World Banner"
      />
    ),
    icon: <LaunchIcon />,
    title: "Launch",
    text: `Nach der Freigabe erfolgt der Launch der Webseite oder Webanwendung. 
    Ich sorge dafür, dass alles reibungslos verläuft und die Webseite oder Webanwendung schnell und sicher läuft.`,
  },
  {
    image: (
      <StaticImage
        src={
          "../images/workflow/closeup-hands-passing-contract-unrecognizable-businessman.jpg"
        }
        alt="Closeuo hands passing contract"
      />
    ),
    icon: <DocsIcon />,
    title: "Documentation and Manual",
    text: `Ich erstelle als Webentwickler eine ausführliche Dokumentation und ein Benutzerhandbuch, 
    damit Sie jederzeit Zugriff auf wichtige Informationen und Funktionen haben.`,
  },
  {
    image: (
      <StaticImage
        src={
          "../images/workflow/feedback-comment-survey-support-response-bar-word.jpg"
        }
        alt="feedback comment survey"
      />
    ),
    icon: <EvaluationIcon />,
    title: "Evaluation & Review",
    text: `Nach dem Launch überprüfe ich als Webentwickler die Ergebnisse und 
    die Performance der Webseite oder Webanwendung. Ich analysiere, 
    welche Funktionen besonders gut ankommen und welche noch verbessert werden können.`,
  },
  {
    image: (
      <StaticImage
        src={
          "../images/workflow/businessman-doing-ok-sign-while-holding-growth-chart.jpg"
        }
        alt="Business doing ok sign"
      />
    ),
    icon: <SmileIcon />,
    title: "Happiness",
    text: `Mein Ziel als Webentwickler ist es, 
    Sie als Kundin oder Kunde zufrieden zu stellen und 
    ein erfolgreiches Webprojekt zu erstellen. 
    Ich stehe Ihnen jederzeit zur Verfügung und unterstütze Sie bei allen Fragen und 
    Anliegen rund um Ihre Webseite oder Webanwendung. Gerne übernehme ich auch die Wartung und 
    weitere Betreuung Ihrer Webandwendung.`,
  },
]

const WorkflowContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const WorkflowItem = styled.div`
  :not(:last-child) {
    padding-bottom: 20px;
  }
  @media (min-width: ${+Breakpoints.desktop + 1}px) {
    width: 400px;
    :not(:last-child) {
      padding-bottom: 170px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (min-width: ${+Breakpoints.tablet +
    1}px) and (max-width: ${+Breakpoints.desktop - 1}px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  margin: 0 auto;
  position: relative;
`

const WorkflowImage = styled.div`
  display: none;
  @media (min-width: ${+Breakpoints.desktop + 1}px) {
    position: absolute;
    display: block;
  }
  @media (min-width: ${+Breakpoints.tablet +
    1}px) and (max-width: ${+Breakpoints.desktop - 1}px) {
    margin-right: auto;
    display: block;
  }
  ${props =>
    props.type === "left" &&
    css`
      order: 2;
      @media (min-width: ${+Breakpoints.tablet +
        1}px) and (max-width: ${+Breakpoints.desktop - 1}px) {
        margin-right: unset;
        margin-left: auto;
      }
    `}
  width: 180px;
  height: 180px;
  border-radius: 50%;
  overflow: hidden;
  > div {
    width: 100%;
    height: 100%;
  }
`

const WorkflowIcon = styled.div`
  width: 50px;
  height: 50px;
  ${props =>
    props.type === "right" &&
    css`
      @media (min-width: ${+Breakpoints.tablet + 1}px) {
        margin-right: auto;
      }
    `}
  ${props =>
    props.type === "left" &&
    css`
      @media (min-width: ${+Breakpoints.tablet + 1}px) {
        margin-left: auto;
      }
    `}
`

const Workflow = () => {
  return (
    <>
      <Seo title="Workflow" />
      <h1>Workflow</h1>
      <motion.div variants={motionItem}>
        <WorkflowContainer>
          {data.map((item, index) => {
            return (
              <WorkflowItem
                key={uniqid()}
                type={index % 2 === 0 ? "left" : "right"}
              >
                <WorkflowImage type={index % 2 === 0 ? "left" : "right"}>
                  {item.image}
                </WorkflowImage>
                <WorkflowInfo type={index % 2 === 0 ? "left" : "right"}>
                  <WorkflowIcon type={index % 2 === 0 ? "left" : "right"}>
                    {item.icon}
                  </WorkflowIcon>
                  <h2>{item.title}</h2>
                  <p>{item.text}</p>
                </WorkflowInfo>
                {index + 1 !== data.length && (
                  <WorkflowLine type={index % 2 === 0 ? "left" : "right"} />
                )}
              </WorkflowItem>
            )
          })}
        </WorkflowContainer>
      </motion.div>
    </>
  )
}

export default Workflow
