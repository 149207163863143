import React from "react"
import styled, { css, keyframes } from "styled-components"
import { useInView } from "react-intersection-observer"
import Breakpoints from "../utils/breakpoints"

import Line from "../svg/workflow-line.svg"

const lineAnimation = () => keyframes`
  to {
  stroke-dashoffset: 0;
  }
`

const WorkflowLineContainer = styled.div`
  display: none;
  @media (min-width: ${Breakpoints.desktop}px) {
    display: block;
  }
  position: absolute;
  width: 300px;
  height: 150px;
  bottom: 0;
`
const StyledLine = styled(Line)`
  transform: translateY(-10px);
  fill: none;
  stroke: var(--color-main);
  stroke-width: 5;
  /* stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 5, 9; */

  stroke-dasharray: 1000;
  transition: all 2s ease-in-out;
  stroke-dashoffset: 1000;

  animation: ${lineAnimation} 3s linear forwards;

  transform: translateY(-10px) scaleX(-1);
  ${props =>
    props.type === "left" &&
    css`
      @media (min-width: ${+Breakpoints.desktop + 1}px) {
        transform: translateY(-10px) scaleX(1);
      }
    `}
  ${props =>
    props.type === "right" &&
    css`
      transform: translateY(-10px);
      @media (min-width: ${+Breakpoints.desktop + 1}px) {
        transform: translateY(-10px) scaleX(-1);
      }
    `}
`

const WorkflowLine = ({ type }) => {
  const { ref, inView } = useInView({
    threshold: 0,
  })
  return (
    <WorkflowLineContainer ref={ref}>
      {inView && <StyledLine type={type} />}
    </WorkflowLineContainer>
  )
}

export default WorkflowLine
