import React from "react"
import styled, { css } from "styled-components"
import { useInView } from "react-intersection-observer"
import Breakpoints from "../utils/breakpoints"

const WorkflowInfoContainer = styled.div`
  @media (min-width: ${+Breakpoints.desktop + 1}px) {
    width: 450px;
  }
  @media (min-width: ${+Breakpoints.tablet +
    1}px) and (max-width: ${+Breakpoints.desktop - 1}px) {
    width: calc(100% - 180px - 2rem);
  }
  line-height: 1.3;
  transition: color 2s ease-in-out;
  path {
    transition: fill 2s ease-in-out;
    fill: #000;
  }
  ${props =>
    props.type === "left" &&
    css`
      @media (min-width: ${+Breakpoints.tablet + 1}px) {
        text-align: right;
        left: 0;
      }
      @media (min-width: ${+Breakpoints.desktop + 1}px) {
        transform: translateX(-81%);
      }
    `}
  ${props =>
    props.type === "right" &&
    css`
      @media (min-width: ${+Breakpoints.tablet + 1}px) {
        text-align: left;
        right: 0;
      }
      right: 0;
      @media (min-width: ${+Breakpoints.desktop + 1}px) {
        transform: translateX(81%);
      }
    `}
  h2 {
    margin: 0.5rem 0;
  }
  p {
    margin: 0;
    color: #000;
  }
  ${props =>
    props.inView &&
    css`
      color: var(--color-main);
      path {
        fill: var(--color-main);
      }
    `}
`

const WorkflowInfo = ({ children, type }) => {
  const { ref, inView } = useInView({
    threshold: 0,
  })
  return (
    <WorkflowInfoContainer ref={ref} type={type} inView={inView}>
      {children}
    </WorkflowInfoContainer>
  )
}

export default WorkflowInfo
